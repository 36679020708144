import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-body pt-0 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabNavigate = _resolveComponent("TabNavigate")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TabNavigate, {
          items: [
        {id: 'overview', router: `/list-process/`+ _ctx.type + `/overview`, name: 'Overview'},
        {id: 'list', router: `/list-process/`+ _ctx.type + `/List`, name: 'List'},
        ]
        }, null, 8, ["items"])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}