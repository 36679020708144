
import {defineComponent, watch} from 'vue'
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import {PROCESS_TYPE} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ProcessPage",
  components: {TabNavigate},
  props: {
    type: {},
  },
  setup(props) {
    const buildHeader = (type) => {
      const title = PROCESS_TYPE[type].names
      setCurrentPageBreadcrumbsWithParams(title,
        [
          {link: false, router: '', text: title}
        ]
      )
    }

    buildHeader(props.type)
    watch(() => props.type, cb => {
      buildHeader(cb);
    })
  }
})
